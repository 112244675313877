import * as React from 'react';
import {
  Button,
  Cluster,
  Cover,
  Divider,
  FormItem,
  Heading,
  Icon,
  Template,
  TextInput,
  Stack,
  useValidateForm,
} from '@pluto-tv/assemble';
import {ICarouselConfigSearch} from 'models/carouselConfigs';
import {carouselConfigSearchValidator} from 'views/programming/hubManager/carousels/validators';
import {useCarouseSearchlLazyLoadProvider} from 'components/carouselList/providers/CarouselSearchLazyLoadProvider';

interface ICarouselSearchProps {
  onSearch: (search: ICarouselConfigSearch) => void;
  onClear: () => void;
  setIsExpanded: (value: boolean) => void;
  favoriteSearch?: React.ReactNode;
}

export const carouselConfigTitleId = 'nameFilter';

const CarouselSearch = ({
  onSearch,
  onClear,
  setIsExpanded,
  favoriteSearch,
}: ICarouselSearchProps): React.ReactElement => {
  const {isLoading} = useCarouseSearchlLazyLoadProvider();
  const {
    model: searchModel,
    onChange: searchOnChange,
    form: searchForm,
    onBlur: searchOnBlur,
    getValidation: searchGetValidation,
    reset: searchReset,
  } = useValidateForm<ICarouselConfigSearch>(carouselConfigSearchValidator, 'ask');

  const handleSearch = async () => {
    const validation = await searchGetValidation();

    if (!validation.state.isValid) {
      return;
    }

    const {model} = validation;
    if (!model.name && !model.region) return;

    onSearch({
      ...model,
      name: model.name || '',
    });
  };

  const handleClear = async () => {
    await searchGetValidation();
    searchReset();
    onClear();
  };

  return (
    <Cover scrolling={true} gutter='medium'>
      <Template label='header'>
        <Stack space='medium'>
          <Cluster align='center' justify='space-between'>
            <Icon icon='tune' space='small' size='large' iconAlign='center'>
              <Heading level='h4'>Search Filters</Heading>
            </Icon>
            <Icon id='closeFilters' icon='collapseleft' size='large' onClick={() => setIsExpanded(false)} />
          </Cluster>
          {favoriteSearch && <>{favoriteSearch}</>}
          <Divider color='graphite' />
        </Stack>
      </Template>

      <Template label='cover'>
        <form
          onSubmit={ev => {
            ev.preventDefault();
            handleSearch();
          }}
        >
          <Stack space='small'>
            <input type='submit' style={{display: 'none'}} />
            <Stack space='xlarge'>
              <FormItem
                {...searchForm.name}
                onBlur={() => {
                  searchOnBlur('name');
                }}
              >
                <TextInput
                  id={carouselConfigTitleId}
                  clearable={true}
                  placeholder='Title'
                  value={searchModel.name}
                  onChange={val => {
                    searchOnChange('name', val);
                  }}
                />
              </FormItem>
            </Stack>
          </Stack>
        </form>
      </Template>

      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button id='clearButton' ghost={true} onClick={handleClear} state={isLoading ? 'disabled' : ''}>
              Clear
            </Button>
            <Button id='searchButton' type='primary' onClick={handleSearch} state={isLoading ? 'thinking' : ''}>
              Search
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Cover>
  );
};

export default CarouselSearch;
