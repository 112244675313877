import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {Box, Button, Cluster, Click, Cover, Expand, Heading, Icon, Sidebar, Template} from '@pluto-tv/assemble';
import {useAppPermissions} from 'app/permissions';
import hubRoutes from 'routes/programming.routes';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';
import HubConfigListTable from 'components/hubList/components/HubConfigListTable';
import {IHubConfigListProps} from 'components/hubList/index';
import HubSearch, {hubConfigTitleId} from 'components/hubList/components/HubSearch';
import {IHubConfigSearch} from 'models/hubConfigs';
import {useHubTableList} from 'components/hubList/hooks/useHubTableList';
import {TSortDirection} from 'models/generic';
import {useHubSearchlLazyLoadProvider} from 'components/hubList/providers/HubSearchLazyLoadProvider';
import {useUserRegions} from 'helpers/useUserRegions';

const HubCreateForm = React.lazy(() => import('components/hubList/components/CreateHubForm'));

const HubConfigList = React.memo(
  ({
    actionsCol = true,
    addNewHubConfig = true,
    inModal = false,
    //showFavoriteSearch = true,
    isSearchExpanded = false,
  }: IHubConfigListProps) => {
    const history = useHistory();
    const {ableTo} = useAppPermissions();

    const canCreate = ableTo('HUB_CREATE');

    const [searchExpanded, setSearchExpanded] = React.useState(isSearchExpanded);
    const [isSearchActive, setIsSearchActive] = React.useState(false);
    const [hubParams, setHubParams] = React.useState<IHubConfigSearch>();

    const {reset: searchReset} = useHubSearchlLazyLoadProvider();

    const {activeRegions, isError: isErrorRegions} = useUserRegions();
    const activeRegionsList = React.useMemo(() => activeRegions.map(ac => ac.code), [activeRegions]);

    const INIT_PARAMS = React.useMemo(
      () =>
        ({
          sortBy: 'createdAt',
          sortDirection: 'dsc' as TSortDirection,
          region: activeRegionsList,
        } as IHubConfigSearch),
      [activeRegionsList],
    );

    const {
      search: searchHubs,
      totalCount: searchTotalCount,
      items: hubConfigItems,
      lazyLoad,
      isLazyLoading,
      isError,
      isLoading,
      searchParams,
    } = useHubTableList(isSearchActive);

    useToggleSearchBarOnSlash(setSearchExpanded, searchExpanded);

    React.useEffect(() => {
      if (searchExpanded) {
        const hubTitleInput = document.getElementById(hubConfigTitleId);
        hubTitleInput?.focus({
          preventScroll: true,
        });
      }
    }, [searchExpanded]);

    React.useEffect(() => {
      if (activeRegionsList.length) {
        if (!isSearchActive) {
          searchHubs(hubParams ?? INIT_PARAMS);
        } else if (isSearchActive && (hubParams?.name || hubParams?.region)) {
          searchHubs(hubParams);
        }
      }
    }, [isSearchActive, searchHubs, hubParams, activeRegionsList.length, INIT_PARAMS]);

    const handleEdit = (id: string) => {
      history.push(hubRoutes.paths.hubEditPreviewPage.replace(':id', id));
    };

    const [createOpen, setCreateOpen] = React.useState(false);

    const openCreate = () => {
      setCreateOpen(true);
    };

    const handleSearch = (search: IHubConfigSearch) => {
      setIsSearchActive(true);
      setHubParams({...INIT_PARAMS, ...search});
    };

    const handleClear = () => {
      setHubParams(undefined);
      if (!isSearchActive) return;
      setIsSearchActive(false);
      searchReset(true);
    };

    const handleSort = (search: IHubConfigSearch) => {
      setHubParams({...(hubParams || {}), ...search});
    };

    const handleCreate = () => {
      if (!isSearchActive) return;
      searchReset();
      searchHubs(searchParams || INIT_PARAMS);
    };

    return (
      <Sidebar fullHeight={true}>
        <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={searchExpanded}>
          <Template label='expandable'>
            <Box
              background='pewter'
              paddingY={inModal ? 'none' : 'medium'}
              paddingRight='medium'
              paddingLeft={inModal ? 'none' : 'medium'}
              fullHeight={true}
            >
              <HubSearch setIsExpanded={setSearchExpanded} onSearch={handleSearch} onClear={handleClear} />
            </Box>
          </Template>
        </Expand>
        <Cover
          scrolling={true}
          gutter='large'
          coverTemplateHeight='100%'
          overflow='auto'
          padding={inModal ? 'none' : {mobile: 'medium', wide: 'large'}}
        >
          <Template label='header'>
            <Cluster justify='space-between' align='center' space='medium'>
              <Box width='300px'>
                <Cluster align='end' space='small'>
                  <Heading level='h1'>Hubs</Heading>
                  <Cluster space='xxsmall' align='center'>
                    <Icon
                      icon='tune'
                      id='expandFilters'
                      space='xxxsmall'
                      verticalAlign='bottom'
                      lineHeight='0px'
                      onClick={() => setSearchExpanded(!searchExpanded)}
                    >
                      {searchTotalCount} Items
                    </Icon>
                  </Cluster>
                </Cluster>
              </Box>
              <div style={{position: 'relative', marginTop: '0.625rem', marginBottom: '-1.625rem', zIndex: 9}}>
                <Cluster space='xxlarge'>
                  <Click
                    id='hubConfigTab'
                    paddingBottom='xlarge'
                    borderBottom={true}
                    borderSize='0.125rem'
                    borderStyle='solid'
                    borderColor='primary'
                    color='white'
                    hoverColor='white'
                    size='large'
                    onClick={() => history.push(hubRoutes.paths.hubManagerPage)}
                  >
                    Hub Configurations
                  </Click>
                  <Click
                    id='carouselConfigTab'
                    paddingBottom='xxlarge'
                    borderBottom={false}
                    borderSize='0.125rem'
                    borderStyle='solid'
                    borderColor='primary'
                    color='dust'
                    hoverColor='primary'
                    size='large'
                    onClick={() => history.push(hubRoutes.paths.carouselListPage)}
                  >
                    Carousel Configurations
                  </Click>
                </Cluster>
              </div>
              <Box width='200px'>
                <Cluster space='small' align='center' justify='end'>
                  {addNewHubConfig ? (
                    <>
                      <Button
                        id='addHubConfig'
                        type='primary'
                        onClick={() => openCreate()}
                        permission={canCreate ? '' : 'hidden'}
                      >
                        + New Hub Config
                      </Button>

                      {createOpen && (
                        <React.Suspense fallback={<React.Fragment />}>
                          <HubCreateForm setCreateOpen={setCreateOpen} onCreate={handleCreate} canCreate={canCreate} />
                        </React.Suspense>
                      )}
                    </>
                  ) : (
                    <Box height='1.7rem'></Box>
                  )}
                </Cluster>
              </Box>
            </Cluster>
          </Template>
          <Template label='cover'>
            <Box
              background='pewter'
              borderTop={true}
              borderSize='2px'
              borderColor='cavern'
              paddingTop={inModal ? 'none' : 'xsmall'}
              paddingBottom='none'
              paddingX={inModal ? 'none' : 'large'}
              fullHeight={true}
            >
              <HubConfigListTable
                onEdit={handleEdit}
                actionsCol={actionsCol}
                hubConfigItems={hubConfigItems}
                lazyLoad={lazyLoad}
                isLazyLoading={isLazyLoading}
                isError={isError || isErrorRegions}
                isLoading={isLoading}
                searchParams={hubParams}
                searchHubs={handleSort}
              />
            </Box>
          </Template>
        </Cover>
      </Sidebar>
    );
  },
);

HubConfigList.displayName = 'HubList';
export default HubConfigList;
