import {createApi} from '@reduxjs/toolkit/query/react';
import {IHubConfig} from 'models/hubConfigs';
import {IListDataPayload} from 'models/generic';
import {getStaggeredQueryWithAuth} from 'features/baseQueryWithAuth/baseQueryWithAuth';

interface IHubConfigQuery {
  offset?: number;
  limit?: number;
  name?: string;
  region?: string[];
  carouselId?: string;
}

function getParamsFromModel<T extends Partial<Record<keyof T, unknown>>>(searchModel: T) {
  return Object.entries(searchModel).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      return [
        ...acc,
        ...value.map(item => ({
          [key]: encodeURIComponent(item),
        })),
      ];
    }

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      return [...acc, {[key]: encodeURIComponent(value)}];
    }
    return acc;
  }, [] as {[key: string]: string | number}[]);
}

export const hubConfigsApi = createApi({
  reducerPath: 'hubConfigsApi',
  tagTypes: ['HubConfigs', 'HubConfigsSearch', 'HubConfig'],
  baseQuery: getStaggeredQueryWithAuth(),
  endpoints: builder => ({
    find: builder.query<IListDataPayload<IHubConfig>, Partial<IHubConfigQuery>>({
      query: (params = {limit: 50, offset: 0}) => {
        const paramsString = getParamsFromModel<Partial<IHubConfigQuery>>(params)
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');
        const searchParams = new URLSearchParams(paramsString);
        return {
          url: `hub/config?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      transformResponse: (response: IListDataPayload<IHubConfig>) => {
        return response.data ? response : {data: [], totalCount: 0};
      },
      providesTags: ['HubConfigs'],
    }),
    search: builder.query<IListDataPayload<IHubConfig>, Partial<IHubConfigQuery>>({
      query: params => {
        const paramsString = getParamsFromModel<Partial<IHubConfigQuery>>(params)
          .map(param => Object.entries(param).map(([key, value]) => `${key}=${value}`))
          .join('&');
        const searchParams = new URLSearchParams(paramsString);
        return {
          url: `hub/config/search?${searchParams.toString()}`,
          method: 'GET',
        };
      },
      transformResponse: (response: IListDataPayload<IHubConfig>) => {
        return response.data ? response : {data: [], totalCount: 0};
      },
      providesTags: ['HubConfigsSearch'],
    }),
    findById: builder.query<IHubConfig, {id: string; populate?: boolean}>({
      query: params => ({
        url: `hub/config/${params.id}?populated=${params.populate}`,
        method: 'GET',
      }),
      providesTags: ['HubConfigs'],
    }),

    insert: builder.mutation<IHubConfig, Partial<IHubConfig>>({
      query: hubConfig => ({
        url: `hub/config`,
        method: 'POST',
        body: hubConfig,
      }),
      invalidatesTags: ['HubConfigs'],
    }),

    update: builder.mutation<IHubConfig, {id: string; hubConfig: Partial<IHubConfig>}>({
      query: ({id, hubConfig}) => ({
        url: `hub/config/${id}`,
        method: 'PUT',
        body: hubConfig,
      }),
      invalidatesTags: ['HubConfig', 'HubConfigsSearch', 'HubConfigs'],
    }),

    delete: builder.mutation<IHubConfig, string>({
      query: (id: string) => ({
        url: `hub/config/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['HubConfigs', 'HubConfigsSearch'],
    }),
  }),
});

export const {
  useLazyFindQuery,
  useFindQuery,
  useFindByIdQuery,
  useInsertMutation,
  useUpdateMutation,
  useDeleteMutation,
  useSearchQuery,
  useLazySearchQuery,
} = hubConfigsApi;
