import * as React from 'react';
// import {useHistory} from 'react-router-dom';
import {
  Box,
  Button,
  ContentBoxes,
  ContentBox,
  Cluster,
  Heading,
  Paragraph,
  Template,
  TDirtyFields,
  Spinner,
  Toast,
} from '@pluto-tv/assemble';
import {uniq} from 'lodash-es';

import {useAppPermissions} from 'app/permissions';
import {INestedCarouselConfigProps} from '../nestedPropsInterface';
// import {useUserRegions} from 'helpers/useUserRegions';

import {ICarouselBlockItem, IHubCarousel, IHubConfig} from 'models/hubConfigs';
import ReorderPanel from 'views/programming/hubManager/hubs/edit/preview/components/ReorderPanel';
import CrudError from 'components/crudError';
import CarouselList from './components/CarouselList';
import {useServiceCarouselBuilder} from 'views/programming/hubManager/hooks/useServiceCarouselBuilderHook';
import contentRoutes from 'routes/content.routes';
import programmingRoutes from 'routes/programming.routes';
import {reorderList} from 'helpers/dragAndDrop';

const CreateCarouselForm = React.lazy(() => import('components/carouselList/components/CreateCarouselForm'));

interface IHubConfigProps extends INestedCarouselConfigProps {
  dirtyFields: TDirtyFields<IHubConfig>;
  setFields: (partialModel: Partial<IHubConfig>) => void;
  model: Partial<IHubConfig>;
  setUndo: (newPresent: IHubCarousel[], options?: any) => void;
  isHubConfigFetching: boolean;
  isHubConfigError: boolean;
  hubConfigError: any;
}

export default ({
  setFields: setHubFields,
  model: hubModel,
  isHubConfigFetching,
  isHubConfigError,
  hubConfigError,
  setUndo,
}: IHubConfigProps): JSX.Element => {
  const [reorderOpen, setReorderOpen] = React.useState<boolean>(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [areTilesLoading, setAreTilesLoading] = React.useState(true);
  const [carouselTilesList, setCarouselTilesList] = React.useState<IHubCarousel[]>();
  const [activeDividerIndex, setActiveDividerIndex] = React.useState<number | null>(null);

  const draggedItemIndex = React.useRef<number | null>(null);

  const {ableTo} = useAppPermissions();
  const {executeQuery} = useServiceCarouselBuilder();

  const canEdit = ableTo('CAROUSEL_EDIT');
  const canDelete = ableTo('CAROUSEL_DELETE');

  const clickableContentTypes = React.useMemo(
    () => ({
      movie: contentRoutes.paths.episodeEditDetailsPage,
      series: contentRoutes.paths.seriesEditDetailsPage,
      channel: programmingRoutes.paths.channelEditDetailsPage,
    }),
    [],
  );

  const getContentTiles = React.useCallback(async () => {
    if (hubModel.region && hubModel.carousels?.length) {
      const carouselIds = uniq(hubModel.carousels.map(ca => ca.id));

      try {
        const response = await executeQuery(hubModel.region, hubModel.kidsOnly || false, carouselIds);

        if (response?.length) {
          const carouselTiles = carouselIds.map(carouselId => {
            const carousel = ((hubModel.carousels || []).find(car => car.id === carouselId) || {}) as IHubCarousel;
            const tilesMapped: ICarouselBlockItem[] = (response.find(item => item.id === carouselId)?.tiles || []).map(
              (resp: any) => {
                const obj = {
                  text: resp.title,
                  imageSrc: resp.tileImage,
                };
                if (Object.keys(clickableContentTypes).includes(resp.contentType)) {
                  obj['linkToRedirect'] = clickableContentTypes[resp.contentType].replace(':id', resp.id);
                }
                return obj;
              },
            );

            return {
              ...carousel,
              tiles: tilesMapped,
            };
          });
          setCarouselTilesList(carouselTiles);
        }
      } catch (error) {
        Toast.error('Error', 'There was an error retrieving carousel list. Please try again.');
      } finally {
        setAreTilesLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubModel.carousels, hubModel.kidsOnly, hubModel.region]);

  React.useEffect(() => {
    if (hubModel.region) {
      if (hubModel.carousels && hubModel.carousels.length > 0) {
        getContentTiles();
      } else {
        setAreTilesLoading(false);
        setCarouselTilesList([]);
      }
    }
  }, [getContentTiles, hubModel.carousels, hubModel.kidsOnly, hubModel.region]);

  const handleApplyOrder = (hubCarousels: IHubCarousel[]) => {
    setHubFields({
      carousels: hubCarousels,
    });
    setUndo(hubCarousels);
  };

  const handleUpdate = (hubCarousels: IHubCarousel[]) => {
    setHubFields({
      carousels: hubCarousels,
    });
    setUndo(hubCarousels);
  };

  const openCreate = () => setCreateOpen(true);

  const handleAddCarousel = (carouselToAdd: Partial<IHubCarousel>) => {
    const newCarouselsState = [...(hubModel.carousels || []), carouselToAdd] as IHubCarousel[];

    setHubFields({carousels: newCarouselsState});
    setUndo(newCarouselsState);

    Toast.success('Success', 'Carousel added to hub.', 8000);
  };

  const handleDrop = () => {
    setActiveDividerIndex(null);

    if (draggedItemIndex.current === null || activeDividerIndex === null || carouselTilesList?.length === 0) return;
    handleApplyOrder(reorderList([draggedItemIndex.current], activeDividerIndex, carouselTilesList as IHubCarousel[]));

    // Reset drag states
    draggedItemIndex.current = null;
  };

  if (isHubConfigFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  if (isHubConfigError) {
    return <CrudError error={hubConfigError} />;
  }

  return (
    <Box height='100%' onDrop={() => handleDrop()} id='carouselList'>
      <ContentBoxes layout='cover' coverScrolling={true}>
        <Template label='contentBoxesCover'>
          <ContentBox title='Carousel List'>
            <Template label='header'>
              <Cluster align='center' justify='space-between' space='medium'>
                <Cluster space='medium' align='end'>
                  <Heading level='h3' color='secondary'>
                    Carousel List
                  </Heading>
                  <Paragraph color='secondary'>{hubModel?.carousels?.length ?? 0} Items</Paragraph>
                </Cluster>
                <Cluster space='small'>
                  <Button
                    state={(hubModel.carousels || []).length < 2 ? 'disabled' : ''}
                    onClick={() => setReorderOpen(true)}
                    permission={canEdit ? '' : 'hidden'}
                  >
                    List View
                  </Button>
                  {reorderOpen && (
                    <ReorderPanel
                      hubCarousels={hubModel.carousels as IHubCarousel[]}
                      onClose={() => setReorderOpen(false)}
                      onApplyOrder={handleApplyOrder}
                    />
                  )}
                  <Button onClick={() => openCreate()} type='primary' permission={canEdit ? '' : 'hidden'}>
                    + Add Carousel
                  </Button>
                  {createOpen && (
                    <React.Suspense fallback={<React.Fragment />}>
                      <CreateCarouselForm
                        addCarouselToHub={true}
                        setCreateOpen={setCreateOpen}
                        primaryBtnText='+ Add'
                        secondPrimaryBtn={false}
                        addCarousel={handleAddCarousel}
                        hubCarouselIds={hubModel.carousels?.map(ca => ca.id)}
                      />
                    </React.Suspense>
                  )}
                </Cluster>
              </Cluster>
            </Template>
            <Template label='content'>
              <CarouselList
                ref={draggedItemIndex}
                carouselTilesList={carouselTilesList}
                isLoading={areTilesLoading}
                onCreate={() => openCreate()}
                onApplyOrder={handleApplyOrder}
                onUpdateCarousels={handleUpdate}
                canEdit={canEdit}
                canDelete={canDelete}
                activeDividerIndex={activeDividerIndex}
                setActiveDividerIndex={setActiveDividerIndex}
              />
            </Template>
          </ContentBox>
        </Template>
      </ContentBoxes>
    </Box>
  );
};
