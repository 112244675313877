import * as React from 'react';
import {Box, Button, Cluster, FormItem, Select, Stack, TextInput, useValidateForm} from '@pluto-tv/assemble';
import {ICarouselConfigSourceService} from 'models/carouselConfigs';
import AssociatedCarouselSelect from 'components/carouselList/components/AssociatedCarouselSelect';
import {carouselConfigDetailsSourceServiceValidator} from 'views/programming/hubManager/carousels/validators';
import {useUserRegions} from 'helpers/useUserRegions';
import {
  getUsedSourceAndServiceOptions,
  getSourceServiceOptions,
  isAssociatedCarouselRequired,
  getFilteredSourceOptions,
  getFilteredServiceOptions,
} from 'components/carouselList/components/helpers/carouselServiceOptions';
import {popoverActionsProps} from 'helpers/popoverActionProps';

const defaultValues = {
  policy: 'Append',
  position: 0,
};

interface ISourceServiceFormProps {
  onCancel: () => void;
  onSubmit: (values: Partial<ICarouselConfigSourceService>) => void;
  // this form needs to know the other source/services in order to not to repeat the same source/service combination
  carouselSourceServiceList: ICarouselConfigSourceService[];
  carouselSourceService?: ICarouselConfigSourceService;
  index?: number;
  activeRegion: string;
}

const SourceServiceForm = ({
  onCancel,
  onSubmit,
  carouselSourceService,
  carouselSourceServiceList,
  index,
  activeRegion,
}: ISourceServiceFormProps): React.ReactElement => {
  const {form, model, onBlur, onChange, setFields, state, setModel} = useValidateForm<ICarouselConfigSourceService>(
    carouselConfigDetailsSourceServiceValidator,
    'immediate',
  );

  const isNew = !Boolean(carouselSourceService);
  const disablePolicyAndPosition = (isNew && !carouselSourceServiceList.length) || index === 0;

  const {activeRegions, isError: isErrorRegions} = useUserRegions();
  const activeRegionsList = React.useMemo(() => activeRegions.map(ac => ac.code), [activeRegions]);
  const activeRegionFiltered = React.useMemo(
    () => (activeRegion.toUpperCase() !== 'ALL' ? [activeRegion] : activeRegionsList),
    [activeRegionsList, activeRegion],
  );

  const sourceServiceOptions = React.useMemo(() => {
    return getSourceServiceOptions(getUsedSourceAndServiceOptions(carouselSourceServiceList, index ?? -1));
  }, [carouselSourceServiceList, index]);

  const sourceOptions = React.useMemo(() => {
    return getFilteredSourceOptions(sourceServiceOptions);
  }, [sourceServiceOptions]);

  const serviceOptions = React.useMemo(() => {
    if (!model.source) return [];
    return getFilteredServiceOptions(model.source, sourceServiceOptions);
  }, [model.source, sourceServiceOptions]);

  const associatedCarouselRequired = React.useMemo(() => {
    if (!model.source || !model.service) return false;
    return isAssociatedCarouselRequired(model.source, model.service);
  }, [model.source, model.service]);

  const associatedCarouselState = React.useMemo(() => {
    if (associatedCarouselRequired && !model.associatedCarousel) return 'error';
    return !associatedCarouselRequired ? 'disabled' : 'normal';
  }, [associatedCarouselRequired, model.associatedCarousel]);

  React.useEffect(() => {
    setModel(!carouselSourceService && !carouselSourceServiceList.length ? defaultValues : carouselSourceService!);
  }, [carouselSourceService, carouselSourceServiceList.length, setModel]);

  React.useEffect(() => {
    if (model.service && serviceOptions.length > 1 && !serviceOptions.find(option => option.value === model.service)) {
      setFields({service: undefined});
    }

    if (serviceOptions.length === 1 && !serviceOptions[0].disabled) {
      setFields({service: serviceOptions[0].value});
    }
  }, [model.service, serviceOptions, setFields]);

  const handleSubmit = () => {
    if (state.isValid && state.isDirty) {
      onSubmit(model);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Box
      padding={popoverActionsProps.padding}
      background={popoverActionsProps.background}
      width={popoverActionsProps.widthWide}
      id='addSourcesContent'
    >
      <Stack space='small'>
        <FormItem {...form.source} onBlur={() => onBlur('source')}>
          <Select
            id='carouselSource'
            placeholder='Select Carousel Source'
            predicate='value'
            value={{value: model.source, label: ''}}
            options={sourceOptions}
            onChange={({value: source}) => {
              setFields({
                source,
                service: '',
                associatedCarousel: undefined,
              });
            }}
          />
        </FormItem>
        <FormItem
          {...form.service}
          onBlur={() => onBlur('service')}
          state={model.source ? 'normal' : 'disabled'}
          helpTextColor='error'
        >
          <Select
            id='carouselService'
            placeholder='Select Carousel Service'
            predicate='value'
            value={{value: model.service, label: ''}}
            options={serviceOptions}
            onChange={({value: service}) => {
              setFields({
                service,
                associatedCarousel: undefined,
              });
            }}
          />
        </FormItem>
        <FormItem
          {...form.associatedCarousel}
          required={associatedCarouselRequired}
          onBlur={() => onBlur('associatedCarousel')}
          state={associatedCarouselState}
          helpText={
            associatedCarouselRequired && !model.associatedCarousel
              ? 'Your Source and/or Service selections make this field required'
              : ''
          }
          helpTextColor='error'
        >
          <AssociatedCarouselSelect
            source={model.source || ''}
            service={model.service || ''}
            associatedCarousel={model.associatedCarousel as {name: string; id: string} | undefined}
            onChange={val => {
              setFields({associatedCarousel: val ? {name: val.label, id: val.value} : undefined});
            }}
            activeRegionsList={activeRegionFiltered}
            isError={isErrorRegions}
          />
        </FormItem>
        <FormItem
          {...form.policy}
          onBlur={() => onBlur('policy')}
          state={disablePolicyAndPosition ? 'disabled' : form.policy?.state}
        >
          <Select
            id='policy'
            clearable={true}
            predicate='value'
            value={{value: model.policy, label: ''}}
            onChange={val => setFields({policy: val?.value})}
            options={[
              {value: 'Append', label: 'Append'},
              {value: 'Replace', label: 'Replace'},
              {value: 'Merge', label: 'Merge'},
            ]}
          />
        </FormItem>
        <FormItem
          {...form.position}
          onBlur={() => onBlur('position')}
          state={disablePolicyAndPosition ? 'disabled' : form.position?.state}
        >
          <TextInput
            id='position'
            type='number'
            onChange={value => {
              onChange('position', value);
            }}
            value={model.position}
          />
        </FormItem>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='xxsmall'>
            <Button id='cancelButton' ghost={true} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              id='addButton'
              type='primary'
              onClick={handleSubmit}
              state={
                !state.isValid ||
                !state.isDirty ||
                (associatedCarouselRequired && !model.associatedCarousel && model.associatedCarousel !== 'error')
                  ? 'disabled'
                  : ''
              }
            >
              {isNew ? '+ Add' : 'Update'}
            </Button>
          </Cluster>
        </Cluster>
      </Stack>
    </Box>
  );
};
export default SourceServiceForm;
